<template>
	<sHeroFlex heroImage="img/team/group.png">
		<template #Title>Работа в ТП Лаб</template>
		<template #Paragraph>
			Один из наших девизов - захват мира :)<br><br>
			Наши клиенты и рынок – это наши вдохновение и возможности. <br>
			Мы хотим стать единственным предпочитаемым решением для наших клиентов.
			Мы так организуем свою работу и создаем такие продукты, чтобы наш скромный девиз воплотился в жизнь!
		</template>
		<template #afterParagraph>
			<Btn path="/about" Size="m" class="mr-t-20">Больше о компании</Btn>
		</template>
	</sHeroFlex>
	<!-- Hero Ends -->

	<!-- Hero List  -->
	<sHeroIconList :Items="benefits" :id="'benefits'">
		<template #Title>У нас классно</template>
	</sHeroIconList>
	<!-- Hero list Ends  -->

	<!-- Vacancy -->
	<sVacancy />
	<!-- Vacancy Ends  -->

	<!-- Our Office  -->
	<sHeroFlex class="mr-t-80" Size="t-4" :heroImage="'img/office/office.png'">
		<template #Title>Наш офис</template>
		<template #Paragraph>У нас клёвый офис - в него можно приходить (а можно и не приходить, если удобно или более
			эффективно делать свою работу дома/за границей/в деревне/...). В офисе не только места для работы, экспериментов и
			уютные тапочки, но ещё есть кофеёк, возможности поштурмовать идею (рабочую или бытовую), порисовать на доске,
			поглядеть на другой продукт, переключиться и поработать руками (например, помочь покидать доски на станок или
			сложить посылку на отправку клиенту), подремать на диване, понаблюдать за рыбками в аквариуме, полопать пупырку,
			погулять по набережной, организовать сабантуй и прочие радости совместного творчества.
		</template>
		<template #afterParagraph>
			<Btn Size="m" Blank class="mr-t-20" :href="'https://goo.gl/maps/XWyLVEMTgi4r2MF49'">Приморский пр. 62-1-1</Btn>
		</template>
	</sHeroFlex>
	<!-- Our Office Ends -->

	<!-- We can be happy section  -->
	<section id="fun">
		<div class="wrapper">
			<div class="g-5 flex-just-center">
				<p class="t-3 mr-b-20 t-w-sb">Мы любим и умеем отдыхать</p>
				<p class="p-2 t-c-sc t-lh-15 ff-alter">
					У нас есть большие встречи коллективом 2 раза в год с выездом на природу, околоспортивными активностями и не
					очень трезвыми посиделками у камина. <br> <br>
					Волейбол/квиз/дегустация вина/настольные игры по пятницам. <br> <br>
					Дружелюбная атмосфера и регулярные активити. <br> <br>
				</p>
			</div>
			<div class="g-7">
				<Gallery :galleryName="relax" :images="relaxImages" :mask="'mask-2'" :Pagination="false" />
			</div>
		</div>
	</section>
	<!-- We can be happy section Ends -->

	<!-- Our office life section  -->
	<sHeroGallery :galleryItems="galleryItems">
		<template #Title>Наша офисная жизнь</template>
		<template #Paragraph>Уже долгое время мы работаем над продуктами и сервисами для бизнеса и клиентов. Наша задача -
			избавить людей от скучной и рутинной работы, оставив людям только самое интересное.
		</template>
	</sHeroGallery>
	<!-- Our office life section Ends -->

	<!-- Art in our DNA section  -->
	<sHeroTransperent Size="t-3" heroImage="img/energy.png">
		<template #Title>
			Творчество в нашей ДНК
		</template>
		<template #Paragraph>
			Преемственность знаний, открытый диалог, гибкие формы работы (вопреки архаичным практикам корпоративного
			менеджмента, бюрократических структур). Каждый может достичь большего, предлагая решения, действуя и обучаясь.
			Рабочий график: каждый может работать сколько угодно часов в день и дней в неделю (можно не стесняться быть
			продуктивным более 40 часов в неделю). Важен - результат.
		</template>
	</sHeroTransperent>
	<!-- Art in our DNA section Ends -->

	<!-- Memes Section  -->
	<sAtention id="memes">
		<template #Title>
			Отдел мемов
		</template>
		<template #Paragraph>
			Мемы нас объединяют, проверяют логику, развлекают, заменяют тысячи слов, а еще выполняют коммуникативную функцию.
			Через мемы мы понимаем подходит ли нам человек по культуре или нет, кажутся ли нам
			содержательными/смешными/занимательными его шутки, близок ли нам его контекст.
		</template>
		<template #Button>
			<Btn Color="white" Size="m" :href="'https://t.me/tplabmemechannel'" Center>Больше мемов</Btn>
		</template>
		<img class="img img-1 bg-white pd-fr-20" src="@/assets/img/memes/1.jpg" alt="Мемы ТП ЛАб">
		<img class="img img-2 bg-white pd-fr-20" src="@/assets/img/memes/2.jpg" alt="Мемы ТП ЛАб">
		<img class="img img-3 bg-white pd-fr-20" src="@/assets/img/memes/3.jpg" alt="Мемы ТП ЛАб">
		<img class="img img-4 bg-white pd-fr-20" src="@/assets/img/memes/4.jpg" alt="Мемы ТП ЛАб">
	</sAtention>
	<!-- Memes Section Ends -->

	<!-- Team section  -->
	<sTeam class="mr-b-40" :buttonOn="false" :teamItems="getTeam" />
	<!-- Team section Ends -->

</template>

<script>
import { mapGetters } from 'vuex'

export default {
	data() {
		return {
			benefits: [
				{ icon: 'icons/work/startup.svg', text: 'Атмосфера настоящего стартапа' },
				{ icon: 'icons/work/dms.svg', text: 'ДМС бизнес-уровня (со стоматологией)' },
				{ icon: 'icons/work/party.svg', text: 'Интересные корпоративные праздники' },
				{ icon: 'icons/work/pop.svg', text: 'Возможность лопать "пупырку" в рабочие дни' },
				{ icon: 'icons/work/tech.svg', text: 'Современные технологии в разработке и коммуникации' },
				{ icon: 'icons/work/Office.svg', text: 'Лофтовый и уютный офис в Санкт-Петербурге' },
				{ icon: 'icons/work/salary.svg', text: 'Оформление по ТК РФ и белая зарплата' },
				// { icon: 'icons/work/mortgage.svg', text: 'Льготная ипотека для ИТ специалистов' },
			],
			galleryItems: [
				{ image: 'img/office_work/17.jpg', alt: 'офис и работа Truepositive' },
				{ image: 'img/office_work/1.jpg', alt: 'офис и работа Truepositive' },
				// {image: 'img/office_work/2.jpg', alt: 'офис и работа Truepositive'},
				// {image: 'img/office_work/3.jpg', alt: 'офис и работа Truepositive'},
				{ image: 'img/office_work/4.jpg', alt: 'офис и работа Truepositive' },
				{ image: 'img/office_work/5.jpg', alt: 'офис и работа Truepositive' },
				{ image: 'img/office_work/6.jpg', alt: 'офис и работа Truepositive' },
				{ image: 'img/office_work/7.jpg', alt: 'офис и работа Truepositive' },
				// {image: 'img/office_work/8.jpg', alt: 'офис и работа Truepositive'},
				{ image: 'img/office_work/9.jpg', alt: 'офис и работа Truepositive' },
				{ image: 'img/office_work/10.jpg', alt: 'офис и работа Truepositive' },
				{ image: 'img/office_work/11.jpg', alt: 'офис и работа Truepositive' },
				{ image: 'img/office_work/12.jpg', alt: 'офис и работа Truepositive' },
				{ image: 'img/office_work/13.jpg', alt: 'офис и работа Truepositive' },
				// {image: 'img/office_work/14.jpg', alt: 'офис и работа Truepositive'},
				{ image: 'img/office_work/15.jpg', alt: 'офис и работа Truepositive' },
				{ image: 'img/office_work/16.jpg', alt: 'офис и работа Truepositive' },
				{ image: 'img/office_work/18.jpg', alt: 'офис и работа Truepositive' },
				{ image: 'img/office_work/19.jpg', alt: 'офис и работа Truepositive' },
				{ image: 'img/office_work/20.jpg', alt: 'офис и работа Truepositive' },
				{ image: 'img/office_work/21.jpg', alt: 'офис и работа Truepositive' },
				{ image: 'img/office_work/2021-12-23 18-27-54.jpg', alt: 'Картинка 1' },
				{ image: 'img/office_work/image 17.png', alt: 'Картинка 1' },
				{ image: 'img/office_work/image 18.png', alt: 'Картинка 1' },
				{ image: 'img/office_work/на выступлении в Технопарке.jpg', alt: 'Картинка 1' },
			],
			relaxImages: [
				{ image: 'img/relax/32.jpg', alt: 'relax' },
				{ image: 'img/relax/33.jpg', alt: 'relax' },
				{ image: 'img/relax/34.jpg', alt: 'relax' },
				{ image: 'img/relax/9-09-01.jpg', alt: 'relax' },
				{ image: 'img/relax/9-09-02.jpg', alt: 'relax' },
				{ image: 'img/relax/9-09-03.jpg', alt: 'relax' },
				{ image: 'img/relax/1.jpg', alt: 'relax' },
				{ image: 'img/relax/2.jpg', alt: 'relax' },
				{ image: 'img/relax/3.jpg', alt: 'relax' },
				{ image: 'img/relax/4.jpg', alt: 'relax' },
				{ image: 'img/relax/5.jpg', alt: 'relax' },
				{ image: 'img/relax/6.jpg', alt: 'relax' },
				{ image: 'img/relax/7.jpg', alt: 'relax' },
				{ image: 'img/relax/8.jpg', alt: 'relax' },
				{ image: 'img/relax/9.jpg', alt: 'relax' },
				{ image: 'img/relax/10.jpg', alt: 'relax' },
				{ image: 'img/relax/11.jpg', alt: 'relax' },
				{ image: 'img/relax/12.jpg', alt: 'relax' },
				{ image: 'img/relax/13.jpg', alt: 'relax' },
				{ image: 'img/relax/y_1.jpg', alt: 'relax' },
				{ image: 'img/relax/y_2.jpg', alt: 'relax' },
				{ image: 'img/relax/y_4.jpg', alt: 'relax' },
				{ image: 'img/relax/y_5.jpg', alt: 'relax' },
				{ image: 'img/relax/y_6.jpg', alt: 'relax' },
				{ image: 'img/relax/crop_1.jpg', alt: 'relax' },
				{ image: 'img/relax/crop_2.jpg', alt: 'relax' },
				{ image: 'img/relax/-baidarka-tur-spb-21.jpeg', alt: 'relax' },
				{ image: 'img/relax/-baidarka-tur-spb-58.jpeg', alt: 'relax' },
				{ image: 'img/relax/10_08_2018_no_logo_(83_of_367).jpeg', alt: 'relax' },
				{ image: 'img/relax/10_08_2018_no_logo_(342_of_367).jpeg', alt: 'relax' },
				{ image: 'img/relax/10_08_2018_no_logo_(364_of_367).jpeg', alt: 'relax' },
				{ image: 'img/relax/IMG_20210711_152129.jpeg', alt: 'relax' },
				{ image: 'img/relax/photo_2019-08-12_14-57-33.jpeg', alt: 'relax' },
				{ image: 'img/relax/Квиз_2019.jpeg', alt: 'relax' },
			]
		}
	},
	computed: {
		...mapGetters([
			'getTeam'
		])
	},
}
</script>

<style lang="scss" scoped>
.img {
	position: absolute;
	transform: scale(0.5);
	border-radius: $s-20;

	&-1 {
		top: -20%;
		left: -20%;
	}

	&-2 {
		top: -20%;
		right: -20%;
	}

	&-3 {
		bottom: -20%;
		left: -20%;
	}

	&-4 {
		bottom: -20%;
		right: -20%;
	}
}

#fun {
	padding: $s-40 0;

	.wrapper {
		.g-5 {
			.flex {
				justify-content: center;
			}
		}

		.g-7 {
			.img-wrapper {
				img {
					width: 100%;
					height: auto;
				}
			}
		}
	}
}

@media screen and (max-width: $tablet-landscape) {
	.img {
		position: absolute;
		transform: scale(0.5);
		border-radius: $s-20;

		&-1 {
			top: -20%;
			left: -10%;
		}

		&-2 {
			top: -20%;
			right: -10%;
		}

		&-3 {
			bottom: -20%;
			left: -10%;
		}

		&-4 {
			bottom: -20%;
			right: -10%;
		}
	}

	#fun {
		.wrapper {
			grid-template-columns: repeat(10, 1fr);

			.g-5 {
				grid-column-end: span 4;
			}

			.g-7 {
				grid-column-end: span 6;
			}
		}
	}
}

@media screen and (max-width: $tablet-landscape-min) {
	.img {
		position: absolute;
		transform: scale(0.4);
		border-radius: $s-20;

		&-1 {
			top: -25%;
			left: -10%;
		}

		&-2 {
			top: -20%;
			right: -20%;
		}

		&-3 {
			bottom: -20%;
			left: -20%;
		}

		&-4 {
			bottom: -25%;
			right: -10%;
		}
	}

	#fun {
		.wrapper {
			grid-template-columns: repeat(9, 1fr);
			grid-row-gap: $s-20;

			.g-5 {
				grid-column-end: span 9;
			}

			.g-7 {
				grid-column-end: span 9;
				order: 1;
				grid-row-start: 1;
			}
		}
	}
}

@media screen and (max-width: $phone-landscape) {
	.img {
		position: absolute;
		transform: scale(0.4);
		border-radius: $s-20;

		&-1 {
			top: -25%;
			left: 15%;
		}

		&-2 {
			top: -20%;
			right: -20%;
			display: none;
		}

		&-3 {
			bottom: -20%;
			left: -30%;
		}

		&-4 {
			bottom: -25%;
			right: -20%;
		}
	}
}

@media screen and (max-width: $phone-portrait) {
	.img {
		border-radius: $s-20;

		&-1 {
			top: -25%;
			left: 0%;
		}

		&-2 {
			top: -20%;
			right: -20%;
			display: none;
		}

		&-3 {
			bottom: -20%;
			left: -15%;
		}

		&-4 {
			bottom: -35%;
			right: -25%;
			display: none;
		}
	}
}
</style>
