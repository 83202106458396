export const Team = ({
    state: () => ({
        team: [
            {mask: 'mask-1', img: 'Dmitriy.jpg', name: 'Дмитрий', position: 'Chief executive officer, основатель'},
            {mask: 'mask-2', img: "Sophia.jpg", name: "Софья", position: "Chief commercial officer, партнер"},
            {mask: 'mask-3', img: 'Sergey.jpg', name: 'Сергей', position: 'Project manager, партнер'},

            {mask: 'mask-2', img: 'Misha_3.jpg', name: 'Михаил', position: 'Партнер'},
            {mask: 'mask-1', img: 'Vlad.jpg', name: 'Влад', position: 'Chief technical officer'},
            {mask: 'mask-1', img: 'Tanya.jpg', name: 'Таня', position: 'Сhief financial officer'},
            {mask: 'mask-4', img: 'Alexsandr.jpg', name: 'Саша', position: 'Key Account Manager'},

            {mask: 'mask-1', img: 'anna.jpg', name: 'Анна', position: 'Account & customer success Manager'},
            {mask: 'mask-7', img: 'Marina.jpg', name: 'Марина', position: 'Head of sales department'},
            {mask: 'mask-4', img: 'Nastya-support.jpg', name: 'Настя', position: 'Head of customer success, senior support engineer'},
            {mask: 'mask-6', img: 'Liza.jpg', name: 'Лиза', position: 'HR Rotor project  manager, Customer care'},

            {mask: 'mask-4', img: 'maks_1.jpg', name: 'Макс', position: 'Head of Support'},
            {mask: 'mask-5', img: 'Leonid.jpg', name: 'Леонид', position: 'Senior technical support engineer'},
            {mask: 'mask-3', img: 'Peter.jpg', name: 'Петя', position: 'Technical support engineer'},
            {mask: 'mask-2', name: 'Сергей', img: 'Sergey-1.jpg', position: 'Technical support engineer'},


            {mask: 'mask-4', img: 'Ivan.jpg', name: 'Иван', position: 'Technical support and supply'},
            {mask: 'mask-7', img: 'mr-Smith.png', name: 'Катя', position: 'Technical support assistant'},
            {mask: 'mask-1', img: 'Stepan.jpg', name: 'Стёпа', position: 'Technical support trainee'},
            {mask: 'mask-3', img: 'Olya.jpg', name: 'Оля', position: 'Heritage projects support'},

            {mask: 'mask-4', img: 'Roma.jpg', name: 'Рома', position: 'Software Development Team Lead'},
            {mask: 'mask-2', img: 'Misha_2.jpg', name: 'Миша', position: 'Head of embedded systems development & backend developer'},
            {mask: 'mask-3', img: 'Dasha_2.jpg', name: 'Даша', position: 'Head of QA'},
            {mask: 'mask-2', img: 'Diana.jpg', name: 'Диана', position: 'QA'},

            {mask: 'mask-5', img: 'Vanya.jpg', name: 'Ваня', position: 'Desktop & mobile developer'},
            {mask: 'mask-5', img: 'Kirill.jpg', name: 'Кирилл', position: 'Backend developer'},
            {mask: 'mask-7', img: 'Yana.jpg', name: 'Яна', position: 'Frontend developer'},
            {mask: 'mask-3', img: 'mr-Smith.png', name: 'Wanted', position: '<a href="/career/vacancy/technical-support-2024" class="t-c-g">Technical support engineer</a>'},

            {mask: 'mask-5', img: 'Katya.jpg', name: 'Катя', position: 'Head of Data Labeling & QA'},
            {mask: 'mask-1', img: 'Nastya-cyp.jpg', name: 'Настя', position: 'Cybercarpenter'},
            {mask: 'mask-5', name: 'Татьяна', img: 'Tatiana.jpg', position: 'Cybercarpenter'},
            {mask: 'mask-6', img: 'Oleg.jpg', name: 'Олег', position: 'Computer vision and ML developer'},


            {mask: 'mask-2', img: 'Denis.jpg', name: 'Денис', position: 'Design & visualization'},
            {mask: 'mask-4', img: 'Nadya.jpg', name: 'Надя', position: 'Tech writer & localization '},
            {mask: 'mask-6', img: 'Masha-2.jpg', name: 'Мария', position: 'Digital artist'},
            {mask: 'mask-5', img: 'Sophia-dm.jpg', name: 'Соня', position: 'Digital marketing'},

            {mask: 'mask-2', img: 'egor.jpg', name: 'Егор', position: 'Accounting Team Assistant'},
            {mask: 'mask-5', img: 'Masha.jpg', name: 'Маша', position: 'HR Rotor Product owner,<br> HR business partner'},
        ],
    }),
    getters: {
        getTeam(state) {
            return state.team
        }
    }
})
